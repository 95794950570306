/**
 * 通用颜色
 */
.Errors__content___2YUpF {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 1032px;
  margin: 0 auto;
  padding-top: 202px;
}
.Errors__content___2YUpF .Errors__icon___7paF5 {
  width: 620px;
  height: 360px;
  margin-bottom: -25px;
}
.Errors__content___2YUpF .Errors__msg___2fm3J {
  margin-top: 0;
  margin-bottom: 24px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
.Errors__content___2YUpF .Errors__btn___1BxLb {
  color: #ff8576;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}
